var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{directives:[{name:"resize-observer",rawName:"v-resize-observer",value:(_vm.onPlayerDimensionsChange),expression:"onPlayerDimensionsChange"}],ref:"wrapper",staticClass:"deleteWrapper"},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('ERow',{staticClass:"d-flex justify-center position-relative",attrs:{"no-gutters":""}},[_c('div',{style:({ 'max-width': '500px' })},[_c('RecordingsDatePicker',{model:{value:(_vm.selectedDay),callback:function ($$v) {_vm.selectedDay=$$v},expression:"selectedDay"}})],1)]),_vm._v(" "),_c('ERow',[_c('ECol',{ref:"startDateContainer",attrs:{"cols":"12","lg":"6","md":"6"}},[(_vm.selectedDay)?_c('RecordingsTimePicker',{attrs:{"selectedDay":_vm.selectedDay,"height":500,"width":_vm.wrapperWidth - 5},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"startDate","rules":{
              required: true,
              date: true,
              compare_dates: ['@endDate', 'startDate'],
              date_format: ['YYYY-MM-DDTHH:mm:ssZ'],
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"mt-2",attrs:{"error-messages":errors,"outlined":"","dense":"","label":"Start Date","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})]}}],null,true)})],1),_vm._v(" "),_c('ECol',{ref:"endDateContainer",attrs:{"cols":"12","lg":"6","md":"6"}},[(_vm.selectedDay)?_c('RecordingsTimePicker',{attrs:{"selectedDay":_vm.selectedDay,"height":500,"width":_vm.wrapperWidth - 5},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"endDate","rules":{
              required: true,
              date: true,
              compare_dates: ['@startDate', 'endDate'],
              date_format: ['YYYY-MM-DDTHH:mm:ssZ'],
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"mt-2",attrs:{"error-messages":errors,"outlined":"","dense":"","label":"End Date","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})]}}],null,true)})],1),_vm._v(" "),_c('ECol',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"loading":_vm.isLoading,"disabled":invalid || _vm.isLoading,"color":"error"},on:{"click":function($event){return _vm.deleteCloudRecordings()}}},[_vm._v("\n            Delete\n          ")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }