<template>
  <v-date-picker
    ref="datepickerref"
    v-model="selectedDay"
    :allowed-dates="isDateAvailable"
    :picker-date.sync="dateCursor"
    no-title
    color="primary"
    full-width
    :min="minDate"
    :max="maxDate"
    class="datepicker"
    data-test-id="date-picker"
  >
    <div
      v-if="isLoadingDays"
      class="e-top-0 position-absolute z-index-1 h-100 w-100 d-flex justify-center align-center"
    >
      <EvercamLoadingAnimation size="FiveXl" />
    </div>
  </v-date-picker>
</template>

<script>
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    value: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      selectedDay: null,
      availableDays: [],
      isLoadingDays: false,
      dateCursor: null,
      oldestSnapshotDate: null,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    maxDate() {
      return this.$moment().toISOString()
    },
    minDate() {
      return this.$moment(
        Math.min(
          this.$moment(this.oldestSnapshotDate),
          this.$moment(this.cameraDialogStore.camera?.createdAt)
        )
      ).toISOString()
    },
    timezone() {
      return this.cameraDialogStore.camera?.timezone || "Europe/Dublin"
    },
  },
  watch: {
    dateCursor(date) {
      const isMonthView =
        this.$moment(date, "YYYY-MM").format("YYYY-MM") === date

      if (!isMonthView) {
        return
      }

      const newDay = this.$moment
        .tz(this.selectedDay, this.timezone)
        .set({
          year: this.$moment(date).year(),
          month: this.$moment(date).month(),
        })
        .toISOString()

      if (newDay === this.selectedDay) {
        return
      }

      this.fetchAvailableDaysForMonth(newDay)
    },
    selectedDay(day) {
      this.$emit("input", day)
    },
    "cameraDialogStore.camera": {
      handler() {
        this.selectedDay = this.$moment().tz(this.timezone).toISOString()
        this.fetchAvailableDaysForMonth()
        this.getOldestSnapshot()
      },
      immediate: true,
    },
  },
  created() {
    this.selectedDay =
      this.value || this.$moment().tz(this.timezone).toISOString()
  },
  mounted() {
    this.fetchAvailableDaysForMonth()
  },
  methods: {
    isDateAvailable(date) {
      if (this.isLoadingDays) {
        return true
      }

      return this.availableDays?.includes(
        Number.parseInt(this.$moment.tz(date, this.timezone).format("D"))
      )
    },
    async fetchAvailableDaysForMonth(day) {
      const m = this.$moment(day || this.selectedDay)
      const year = m.format("YYYY")
      const month = m.format("MM")

      try {
        this.isLoadingDays = true
        const { days } = await EvercamApi.recordings.availableDays({
          cameraId: this.cameraDialogStore.camera?.exid,
          year,
          month,
        })
        this.availableDays = days
      } catch (err) {
        this.$errorTracker.save(err)
      } finally {
        this.isLoadingDays = false
      }
    },
    async getOldestSnapshot() {
      try {
        const before = await EvercamApi.recordings.oldest(
          this.cameraDialogStore.camera?.exid,
          {
            apiKey: this.cameraDialogStore.camera?.userApiKey,
            apiId: this.cameraDialogStore.camera?.userApiId,
          }
        )
        this.oldestSnapshotDate = before.createdAt
      } catch (_) {
        this.oldestSnapshotDate = this.$moment()
      }
    },
  },
}
</script>
