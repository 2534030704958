<template>
  <div
    ref="iframeContainer"
    :style="{ height: iframeHeight + 'px !important' }"
  >
    <iframe
      v-if="camera"
      class="demos__iframe w-100"
      :src="demoUrl"
      :style="{ height: iframeHeight + 'px !important' }"
      frameborder="0"
      allowfullscreen="allowfullscreen"
      @load="onIframeLoaded"
    ></iframe>
  </div>
</template>

<script>
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  name: "Demo",

  props: {
    camera: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      iframeHeight: 100,
      demoUrl: null,
    }
  },
  watch: {
    camera: {
      async handler() {
        const { token } = await AdminApi.users.impersonateUser(
          this.camera.userEmail
        )
        this.demoUrl = `${this.$config.public.dashURL}/projects/${this.camera.projectExid}/${this.camera.exid}?impersonation_token=${token}&demo=true`
      },
      immediate: true,
    },
  },
  mounted() {
    this.updateIframeHeight()
    this.$nextTick(() => {
      this.$addEventListener("resize", this.onResize)
    })
  },
  methods: {
    onIframeLoaded() {
      this.updateIframeHeight()
    },
    onResize() {
      this.updateIframeHeight()
    },
    updateIframeHeight() {
      this.iframeHeight =
        window.innerHeight -
          this.$refs?.iframeContainer?.getBoundingClientRect()?.top -
          20 || 0
    },
  },
}
</script>

<style lang="scss" scoped>
.demos {
  &__iframe {
    width: 100%;
    box-shadow: none;
  }
}
</style>
