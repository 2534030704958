<template>
  <ERow no-gutters>
    <!--SNAPSHOT-->
    <ECol cols="8">
      <v-card flat>
        <v-card-title class="pl-3 py-2">
          <ERow justify="between" align="center" class="px-4">
            <span>Milestones</span>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              max-width="300"
            >
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" class="datePicker pl-2" v-on="on">
                  {{ formattedDate }}
                  <v-btn icon>
                    <v-icon>fa fa-calendar-days</v-icon>
                  </v-btn>
                </div>
              </template>
              <RecordingsDatePicker v-model="selectedDay" />
            </v-menu>
          </ERow>
        </v-card-title>
        <v-card-text>
          <RecordingsTimePicker
            v-if="selectedDay"
            v-model="datetime"
            :disable-play-button="false"
            :selectedDay="selectedDay"
            @image-load="onSnapshotLoad"
            @input="updateTimestamp"
          >
          </RecordingsTimePicker>
        </v-card-text>
      </v-card>
    </ECol>

    <ECol
      cols="4"
      class="pr-4"
      :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : ''"
    >
      <v-card flat>
        <v-card-title class="py-2 pl-0"> Add milestone </v-card-title>
        <div class="w-100 d-flex">
          <!-- Timestamp -->
          <v-text-field
            v-model="timestampModel"
            dense
            disabled
            label="timestamp"
            class="custom-input mr-2"
          ></v-text-field>

          <!-- Name -->
          <v-text-field
            v-model="layerName"
            dense
            label="Milestone name"
            class="custom-input"
          ></v-text-field>
        </div>
        <v-btn small color="primary" @click="createMilestoneLayer"
          >Create milestone</v-btn
        >
      </v-card>

      <v-divider class="mt-3"></v-divider>

      <v-card flat>
        <v-card-title class="py-2 pl-0"> Milestones </v-card-title>
        <div
          v-if="!layers.length"
          class="caption grey--text text--darken-1 text-center"
        >
          No milestones found
        </div>
        <div
          v-show="layers.length"
          ref="timelineContainer"
          v-resize-observer="handleResizeTimelineContainer"
          class="timeline-container styled-scrollbars"
        >
          <v-timeline align-top dense right>
            <v-timeline-item
              v-for="(layer, index) in layers"
              :key="index"
              :color="index == 0 ? 'primary' : 'grey'"
              class="timeline-item"
              right
              small
            >
              <div class="d-flex justify-space-between">
                <!-- NAME -->
                <h4 :class="{ 'primary--text': index == 0 }">
                  {{ layer.name }}
                  <span
                    v-if="index == 0"
                    class="caption primary--text font-weight-light"
                    >(Xray/Compare default)</span
                  >
                </h4>

                <v-btn
                  icon
                  x-small
                  class="delete-icon"
                  @click="deleteMilestoneLayer(layer.id)"
                >
                  <v-icon small color="error">fa fa-trash-can </v-icon>
                </v-btn>
              </div>
              <!-- TIMESTAMP -->
              <span class="grey--text text--darken-1 caption">{{
                $moment(layer.startAt).format("MMMM DD, YYYY h:mm A")
              }}</span>
              <!-- THUMBNAIL -->
              <img
                :src="getThumbnailUrl(layer.startAt)"
                class="milestone-thumbnail rounded pt-1"
                @error="handleImageError"
              />
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-card>
    </ECol>
  </ERow>
</template>

<script>
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import RecordingsDatePicker from "@/components/cameraTabs/RecordingsDatePicker"
import RecordingsTimePicker from "@/components/cameraTabs/RecordingsTimePicker"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useAccountStore } from "@/stores/account"

export default {
  name: "Milestones",
  components: {
    RecordingsDatePicker,
    RecordingsTimePicker,
  },
  data() {
    return {
      datetime: null,
      formattedTimestamp: null,
      image: null,
      imageDimension: null,
      selectedDay: null,
      isPlaying: false,
      isProcessing: false,
      menu: false,
      timestampModel: null,
      layerName: null,
      layers: [],
      focusedInterval: {
        startTime: "2023-05-10T00:00:00+01:00",
        endTime: "2023-07-10T00:00:00+01:00",
      },
      mainHeight: 0,
      mainWidth: 0,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useAccountStore),
    cameraTimezone() {
      return this.cameraDialogStore.camera?.timezone || "Europe/Dublin"
    },
    formattedDate() {
      //in readable format
      return this.$moment(this.selectedDay).format("DD MMM YYYY")
    },
    recordingsEndpoint() {
      return `${this.$config.public.apiURL}/cameras/${this.cameraDialogStore.camera.exid}/recordings/snapshots`
    },
    cameraExid() {
      return this.cameraDialogStore.camera.exid
    },
  },
  watch: {
    datetime() {
      this.formattedTimestamp = this.$moment(this.datetime)
        .tz(this.cameraTimezone)
        .format("YYYY-MM-DD HH:mm:ss")
      this.updateUrlState()
    },
    cameraExid() {
      this.fetchCameraLayers()
    },
  },
  mounted() {
    const { timestamp } = this.getSubQueryParams()
    this.selectedDay = this.getFormattedDate(timestamp || new Date())
    this.datetime = this.getFormattedDate(
      this.$route.query.timestamp || new Date()
    )
    this.fetchCameraLayers()
  },
  methods: {
    getThumbnailUrl(timestamp) {
      const processedTimestamp = this.$moment(timestamp).format()
      const snapshotUrl = `${this.recordingsEndpoint}/${processedTimestamp}?view=true&authorization=${this.accountStore.token}`

      return this.$imgproxy.get360pResizedImageUrl(snapshotUrl)
    },
    handleImageError(e) {
      e.target.src = "/unavailable.jpg"
    },
    getFormattedDate(date) {
      return this.$moment
        .tz(date, this.cameraTimezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
    },
    onSnapshotLoad(image) {
      this.image = image.src
      if (
        image?.height !== this.imageDimension?.height ||
        image?.width !== this.imageDimension?.width
      ) {
        this.imageDimension = {
          height: image?.height,
          width: image?.width,
        }
      }
    },
    updateTimestamp(timestamp) {
      this.timestampModel = this.$moment(timestamp).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      )
    },
    updateUrlState() {
      this.updateSubQuery({
        subQueryObj: { timestamp: this.formattedTimestamp },
      })
    },

    async createMilestoneLayer() {
      try {
        await EvercamApi.layers.createLayer(
          this.cameraDialogStore.camera.exid,
          {
            name: this.layerName,
            startAt: this.timestampModel,
            layerType: LAYER_TYPES.MILESTONE,
            shapes: JSON.stringify([[], [], []]),
          }
        )
        this.$notifications.success("MILESTONE updated successfully.")

        this.fetchCameraLayers()
      } catch (error) {
        this.$notifications.error({ text: "Creating MILESTONE failed!", error })
      }
    },
    async deleteMilestoneLayer(layerId) {
      try {
        await EvercamApi.layers.deleteLayer(
          this.cameraDialogStore.camera.exid,
          layerId
        )
        this.$notifications.success("MILESTONE deleted successfully.")
        this.fetchCameraLayers()
      } catch (error) {
        this.$notifications.error({ text: "Deleting MILESTONE failed!", error })
      }
    },
    async fetchCameraLayers() {
      try {
        const layers = await EvercamApi.layers.getLayers(
          this.cameraDialogStore.camera.exid,
          {
            layerType: LAYER_TYPES.MILESTONE,
          }
        )
        this.layers = layers
          .filter((layer) => layer.layerType === LAYER_TYPES.MILESTONE)
          .sort((a, b) =>
            this.$moment(a.startAt).isBefore(b.startAt) ? 1 : -1
          )
        this.handleResizeTimelineContainer()
      } catch (error) {
        this.$notifications.error({ text: "Fetching layers failed", error })
      }
    },
    handleResizeTimelineContainer() {
      const top = this.$refs.timelineContainer.getBoundingClientRect().top || 0
      this.$refs.timelineContainer.style.height = `${
        window.innerHeight - top - 20
      }px`
    },
  },
}
</script>

<style lang="scss" scoped>
.datePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  height: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #4a4a4a;
  background-color: #fff;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }
}

.milestone-thumbnail {
  width: 300px;
  height: 100%;
  object-fit: cover;
}

.timeline-container {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>

<style>
.timeline-container .v-timeline-item__divider {
  min-width: 30px !important;
  margin-right: 20px;
}
.timeline-container .v-timeline--dense:not(.v-timeline--reverse)::before {
  left: 19px !important;
}

.timeline-container .v-timeline-item {
  justify-content: start !important;
  padding-top: 10px;
  padding-left: 5px;
}
.timeline-container .v-timeline-item:hover {
  background-color: #dadada5e;
}

.timeline-container .v-timeline {
  padding-top: 5px !important;
}

timeline-container .v-timeline-item__body {
  flex: 1 !important;
}

.timeline-container .v-timeline-item .delete-icon {
  display: none;
}

.timeline-container .v-timeline-item:hover .delete-icon {
  display: inline-block;
}
</style>
