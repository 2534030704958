<template>
  <div>
    <div class="subtitle-1 font-weight-bold ml-2 my-2">
      Delete Cloud Recordings
    </div>
    <DeleteCloudRecordings :exid="cameraDialogStore.camera.exid" />
    <v-divider class="my-2" />
    <div class="subtitle-1 font-weight-bold ml-2 my-2">Delete Camera</div>
    <DeleteCameraDialog />
  </div>
</template>

<script>
import DeleteCloudRecordings from "@/components/cameraTabs/DeleteCloudRecordings"
import DeleteCameraDialog from "@/components/cameras/DeleteCameraDialog"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  components: {
    DeleteCameraDialog,
    DeleteCloudRecordings,
  },
  computed: {
    ...mapStores(useCameraDialogStore),
  },
}
</script>
