<template>
  <v-card flat>
    <v-card-text>
      <ECol cols="12" class="pa-0">
        <v-data-table
          ref="snapshotExtractions"
          :headers="showHeaders"
          :items="extractions"
          :options.sync="options"
          class="admin-data-table"
          dense
          :loading="loading"
          loading-text="Please wait..."
          calculate-widths
          item-key="id"
          :mobile-breakpoint="0"
          :server-items-length="total"
          :footer-props="{
            'items-per-page-options': [50, 100, 200],
          }"
        >
          <template #top>
            <v-container fluid class="py-0">
              <ERow>
                <v-spacer />
                <ECol cols="4" class="text-right pr-0 d-flex justify-end">
                  <CamerasSnapshotExtractor
                    :cameras="[cameraDialogStore.camera]"
                    @extraction-added="fetchExtractions"
                  />
                  <CopyTableToClipboard
                    :headers="headers"
                    :items="filteredItems"
                  />
                </ECol>
              </ERow>
            </v-container>
          </template>
          <template #item.delete="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  x-small
                  :disabled="!actionIsEnabled(item)"
                  class="cursor-pointer mx-n1"
                  @click="deleteExtraction(item)"
                  v-on="on"
                >
                  <v-icon color="primary" small> far fa-trash-alt </v-icon>
                </v-btn>
              </template>
              <span>Delete Extraction</span>
            </v-tooltip>
          </template>
          <template #item.progress="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  x-small
                  :disabled="!actionIsEnabled(item)"
                  class="cursor-pointer mx-n1"
                  v-bind="attrs"
                  @click="getExtractionStatus(item)"
                  v-on="on"
                >
                  <v-icon small color="primary"> fa-clock </v-icon>
                </v-btn>
              </template>
              <span>Check Extraction Progress</span>
            </v-tooltip>
          </template>
          <template #item.interval="{ item }">
            <span>{{ secondsToHms(item.interval) }}</span>
          </template>
          <template #item.createdAt="{ item }">
            <span>{{ parseDateTime(item.createdAt, true) }}</span>
          </template>
          <template #item.fromDate="{ item }">
            <span>{{ parseDateTime(item.fromDate) }}</span>
          </template>
          <template #item.toDate="{ item }">
            <span>{{ parseDateTime(item.toDate) }}</span>
          </template>
          <template #item.injectToCr="{ item }">
            <ShowActiveValue :value="item.injectToCr" />
          </template>
          <template #item.jpegsToDropbox="{ item }">
            <ShowActiveValue :value="item.jpegsToDropbox" />
          </template>
        </v-data-table>
        <v-dialog v-model="showStatusModal" max-width="300">
          <v-card>
            <v-card-title>Snpshot Extraction Status</v-card-title>
            <v-divider />
            <v-card-text class="pt-5">
              <v-simple-table class="admin-data-table" dense>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">Jpegs Extracted</th>
                      <th class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ extractedJpegs }}</td>
                      <td>{{ status }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="showStatusModal = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </ECol>
    </v-card-text>
  </v-card>
</template>

<script>
import SnapshotExtrationsHeaders from "@/components/cameraTabs/snapshotExtractionsHeaders"
import { secondsToHms } from "@evercam/shared/utils"
import CamerasSnapshotExtractor from "@/components/cameras/CamerasSnapshotExtractor"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import ShowActiveValue from "@/components/ShowActiveValue"
import {
  SnapshotExtractionStatus,
  SnapshotExtractionType,
} from "@evercam/shared/types"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  components: {
    CamerasSnapshotExtractor,
    CopyTableToClipboard,
    ShowActiveValue,
  },
  props: {
    exid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      showStatusModal: false,
      extractedJpegs: 0,
      status: "null",
      selectedHeaders: [],
      headers: SnapshotExtrationsHeaders,
      extractions: [],
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 50,
      },
      total: 0,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s))
    },
    filteredItems() {
      let filteredItems = this.$refs.snapshotExtractions?.internalCurrentItems

      return filteredItems ? filteredItems : this.extractions
    },
  },
  watch: {
    exid: {
      immediate: true,
      async handler(id) {
        if (!this.loading && id) {
          this.fetchExtractions()
        }
      },
    },
    options: {
      immediate: true,
      async handler() {
        if (!this.loading) {
          this.fetchExtractions()
        }
      },
    },
  },
  created() {
    this.initialize()
    this.loading = false
  },
  methods: {
    parseDateTime(dateTime, created) {
      return created
        ? this.$moment(dateTime).format("DD MMM YYYY HH:mm:ss")
        : this.$moment(dateTime).format("DD MMM YYYY")
    },
    actionIsEnabled(item) {
      return (
        this.isProcessing(item.status) &&
        item.type !== SnapshotExtractionType.Timelapse
      )
    },
    initialize() {
      let keys = Object.values(this.headers)
      let initColumns = keys.filter((e) => {
        return e.value != "apiId" && e.value != "apiKey"
      })
      this.selectedHeaders = initColumns
    },
    isProcessing(status) {
      return status === SnapshotExtractionStatus.Processing
    },
    secondsToHms,
    async fetchExtractions() {
      try {
        let { page, itemsPerPage } = this.options
        this.loading = true
        const { items, total } = await AdminApi.cameras.getSnapshotExtractions({
          params: {
            cameraExid: this.exid,
            limit: itemsPerPage,
            page: page,
          },
        })
        this.extractions = items
        this.total = total
      } catch (error) {
        this.$notifications.error({
          text: "Extractions could not be loaded!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    async getExtractionStatus(extraction) {
      try {
        const { jpegs, status } =
          await AdminApi.extractions.getExtractionStatus(
            this.exid,
            extraction.id,
            {
              type: extraction.type,
            }
          )
        this.extractedJpegs = jpegs
        this.status = status
        this.fetchExtractions()
        this.showStatusModal = true
      } catch (error) {
        this.showStatusModal = false
        this.$notifications.error({
          text: "Could not load extraction status!",
          error,
        })
      }
    },
    async deleteExtraction(extraction) {
      if (
        await this.$confirmDialog.open({
          title: "Cancel snapshot extraction event ?",
          message: "This will cancel snapshot extraction event!",
        })
      ) {
        try {
          await AdminApi.extractions.deleteSnapshotExtraction(
            this.exid,
            extraction.id,
            {
              type: extraction.type,
            }
          )
          this.fetchExtractions()
          this.$notifications.success("Snaphot Extaction has been deleted.")
        } catch (error) {
          this.$notifications.error({
            text: "Delete snapshot extraction failed!",
            error,
          })
        }
      }
    },
  },
}
</script>
